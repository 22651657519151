@font-face {
    font-family: "clash";
    src: url("./fonts/clash.woff2") format("woff2"),
      url("./fonts/clash.woff") format("woff"),
      url("./fonts/clash.ttf") format("truetype");
    font-weight: 200 700;
    font-display: swap;
    font-style: normal;
  }

  .clash{
    font-family: 'clash', sans-serif;
  }
  
  .clash_btn {
    font-family: 'clash', sans-serif;
    font-weight: 500;
  }

  .clash_title{
    font-family: 'clash', sans-serif;
    font-weight: 300;
  }

  .clash_subtitle{
    font-family: 'clash', sans-serif;
    font-weight: 500;
  }
  