.lit-icon-path {
    fill: white;
    fill-rule: nonzero;
}

.lit-icon-rect {
    fill: white;
    fill-rule: nonzero;
}

.lit-icon-circle {
    fill: rgb(228, 32, 35);
}